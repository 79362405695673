import Contact from "../../Components/Contact/Contact";
import Header from "../../Components/Header/Header";
import './ContactPage.css';
export default function ContactPage(){
    return(
        <>
        <Header/>
        <div className="contactpage">
        <Contact/>
        </div>
       
        </>
    )

}